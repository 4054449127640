import React from 'react';
import { CaseStudyRoot } from '@/features/new-landing-page/pages/case-study';
import { CaseStudiesPages } from '@/features/new-landing-page/pages/customers-content';

const title = `Branch`;
const link = CaseStudiesPages[title];

export default function BranchCaseStudy() {
  return <CaseStudyRoot link={link} title={title} />;
}

export const Head = () => <title>Visually i.o - ${title} Case Study</title>;
